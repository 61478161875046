.tags-out {
  background-color: var(--clr-theme1);
  // color: var(--clr-black);
  // transition: var(--transition);
}

.tags {
  top: 7rem;
  position: sticky;
  // background-color: yellow;

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding: var(--gap-xs);
    // color: var(--clr-light);
  }
}


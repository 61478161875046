.tag {
  margin: var(--gap-xs);
  padding: calc(2 * var(--gap-xs));
  background-color: var(--clr-dark);
  color: var(--clr-light);
  // border-radius: 0.4rem;
  border: 0.2rem solid var(--clr-dark);
  transition: var(--transition);
  transform: var(--skew-right);
  font-weight: var(--font-w-semibold);
  line-height: 1;

  &.chosen {
    background-color: var(--clr-accent1);
    color: var(--clr-dark);
  }

  &:hover {
    background-color: var(--clr-accent1);
    color: var(--clr-dark);
    cursor: pointer;
  }
}
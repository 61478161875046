@use '../scss-utils' as u;

.wrapper {
  margin: auto;
  margin-top: 3rem;
  width: 20rem;
  position: relative;

  @media (min-width: u.$bp-md) {
    width: 30rem;
  }

  @media (min-width: u.$bp-lg) {
    width: 50rem;
  }

  @media (min-width: u.$bp-xlg) {
    width: 60rem;
  }  

  @media (min-height: 60rem) {
    margin-top: 5rem;
  }
}

.letter {
  &.sent {
    &::before {
      padding-top: 15rem;
      height: 100%;
      content: "thanks for contacting me \A I will be in touch soon";
      line-height: 1.5;
    }
    .side__bottom {
      display: none;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    content: "";
    white-space: pre;
    text-align: center;
    text-transform: uppercase;
    line-height: 0;
    
    padding: 0;
    font-size: 1.5rem;
    font-family: var(--font-title);
    font-weight: var(--font-w-medium);
    background-color: var(--clr-dark);
    color: var(--clr-accent1);
    transition: 1s ease-in-out;
  }

  position: relative;
  border: 1rem solid var(--clr-dark);
  background-color: black;
  padding: 2rem;
  transition: 1s ease-in-out;

  h1 {
    font-size: var(--font-lg);
    font-family: var(--font-title);
    color: var(--clr-accent1);
    margin-bottom: 2rem;
    text-align: center;

    @media (min-width: u.$bp-md) {
      font-size: var(--font-xl);
    }
  }

  textarea, input, button {
    border: none;
    line-height: 1.5rem;
    font-size: var(--font-xs);
    font-weight: var(--font-w-semibold);
    font-family: var(--font-regular);
    outline: none;

    @media (min-width: u.$bp-md) {
      font-size: var(--font-sm);
    }
  }

  textarea, input {
    color: var(--clr-light);
    background-color: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='24'><rect fill='rgb(181, 138, 83)' x='0' y='23' width='10' height='1'/></svg>");
  }

  textarea {
    width: 100%;
    height: 10rem;
    resize: none;
   }
   
  input {
    width: 60%;
    margin-bottom: var(--gap-sm);
  }

  .side {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    button {
      padding: 0.3rem;
      border-radius: 0.5rem;
      border: 0.2rem solid var(--clr-dark);
      font-weight: var(--font-w-bold);
      background-color: var(--clr-accent1);
      color: var(--clr-dark);
      // margin: var(--gap-md);
      margin: 0;
      margin-left: var(--gap-sm);
      
      &:hover {
        cursor: pointer;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      margin: 0;
      // border: red 1px solid;
    }
    .captcha {
      transform: scale(0.6);
      transform-origin: 100% 100%;

      @media (min-width: u.$bp-md) {
        transform: scale(0.8);
      }
    }
   }
}








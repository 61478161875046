@use '../scss-utils' as u;

.bloghome {
  min-height: 100vh;

  // .footer {
  //   background: var(--clr-theme1);
  // }

  // .navout {
  //   background-color: var(--clr-theme1);
  //   a:hover {
  //     color: var(--clr-accent1);
  //   }
  //   span {
  //     color: var(--clr-theme2);
  //   }

  //   .nav__search input, button {
  //     background-color: var(--clr-theme1);
  //   }

  //   .nav__search button:hover {
  //     background-color: var(--clr-theme1);
  //   }
  // }

  .tags-out {
    background: var(--clr-accent2);
    background: transparent;

    .section-title {
      -webkit-text-stroke: 0.03rem var(--clr-light);
    }
    .tag{
      // border-color: var(--clr-light);
      // border-radius: 0.7rem;
      padding: 0.5rem;
      // background: var(--clr-light);
      // color: var(--clr-dark);
      // &:hover {
      //   background: var(--clr-accent1);
      // }
    }
  }
}

.bloghome__main {
  // border: solid 1px red;
  display: grid;
  // gap: 1rem;
  grid-template-columns: 1fr;
  background: var(--clr-gradient2);
  
  @media (min-width: u.$bp-md) {
    grid-template-columns: 2fr 7fr;
  }
}


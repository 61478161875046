@use '../scss-utils' as u;

.blog {

  .navout {
    background-color: var(--clr-theme1);
    a:hover {
      color: var(--clr-accent1);
    }
    span {
      color: var(--clr-accent1);
    }

    .nav__search input, button {
      background-color: var(--clr-theme1);
    }

    .nav__search button:hover {
      background-color: var(--clr-theme2);
    }

    .nav__logo{
      &__title:hover {
        color: var(--clr-theme2);
      }
      &__theme {
        display: block;
      }
    }
  }

  .tags-out {
    
    // border: 1px solid orange;
    
    @media (min-width: u.$bp-lg) {
      background-color: var(--clr-black);
      padding-top: 10rem;
      color: var(--clr-theme2);
      .tag {
        border-color: var(--clr-theme2);
        background-color: inherit;
      }
      .tag:hover {
        background-color: var(--clr-theme1);
      }
    }


    .section-title {
      margin: var(--gap-sm);
   
      @media (min-width: u.$bp-lg) {
        margin: var(--gap-lg);
      }
    } 
  }

  .blog__main__current {
    // border: solid 1px red;
    background-color: var(--clr-black);
    color: var(--clr-light);
    display: grid;
    // gap: 1rem;
    grid-template-columns: 1fr;
    transition: var(--transition);
    
    @media (min-width: u.$bp-lg) {
      grid-template-columns: 1fr 3fr 1fr;
    }
  }

  &.light {
    .blog__main__current {
      background-color: var(--clr-light);
      color: var(--clr-black);
    }

    @media (min-width: u.$bp-lg) {
    .tags-out {
      background-color: var(--clr-light);
      .tag {
        color: var(--clr-black);
        background-color: var(--clr-theme2);
        &:hover {
          background-color: var(--clr-accent1);
        }
      }
    }

    .toc-out {
      .toc {
        color: var(--clr-accent2);

        li {
          &.active > a {
            color: var(--clr-accent3);
          }
        }
      }
    }
    }
  }
}




@use '../scss-utils' as u;
// .home {
//   background-color: var(--clr-light);
//   // border: 5px solid var(--clr-font);
// }

.home__main {
  // border: solid 1px red;
  // background-color: var(--clr-mid);
  display: grid;
  // gap: 1rem;
  grid-template-columns: 1fr;
  
  @media (min-width: u.$bp-md) {
    grid-template-columns: 1fr 4fr;
  }

  .tags-out {
    grid-row: 1 / 3;
  }
}


@use '../scss-utils' as u;

.contact {
  background: var(--clr-gradient);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .navout {
    background: transparent;

    .nav__search {
      display: none;
    }
  }

  .footer {
    background: transparent;
    position: absolute;
    bottom: 0;
    display: none;
    transition: var(--transition);

    @media (min-height: 50rem) {
      display: block;
    }
  }
}


@use '../scss-utils' as u;

.more {
  background-color: var(--clr-accent2);
  // @media (min-width: u.$bp-lg) {
  //   padding: 1rem 5rem;
  // }

  .articles__content {
    @media (min-width: u.$bp-md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: u.$bp-xlg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .article {

    height: 10rem;
    // text-align: center;
    
    &__title {
      font-size: var(--font-xs);
    }
    &__tags {
      font-size: var(--font-xs);
    }

    @media (min-width: u.$bp-md) {
      &__title {
        font-size: var(--font-sm);
      }
      &__tags {
        font-size: var(--font-sm);
      }
    }
  }
}




@use '../scss-utils' as u;

.bloglist {
  min-height: 60vh;
  // background: var(--clr-theme1);

  // h1 {
  //   font-size: 2rem;
  //   padding: 1rem;
  //   display: none;
  // }

  &__search {
    text-align: center;
    text-transform: uppercase;
    font-family: var(--font-title);
    font-size: var(--font-md);
    
    margin: 2rem;
    margin-bottom: 1rem;

    // &__msg {
    //   // font-size: var(--font-md);
    //   // margin-bottom: 1rem;
    // }

    &__item {
      color: var(--clr-accent2);
      // font-weight: var(--font-w-medium);
      // -webkit-text-stroke: 0.03rem var(--clr-light);
    }

    &__clear {
      font-size: 1rem;
      font-weight: var(--font-w-bold);
      background-color: var(--clr-theme2);
      border-radius: 0.4rem;
      padding: 0 0.3rem;

      &:hover {
        cursor: pointer;
        background-color: var(--clr-accent1);
        transition: var(--transition);
      }
    }

    @media (min-width: u.$bp-md) {
      margin-top: 4rem;

      &__msg {
        font-size: 1.5rem;
      }
      &__item {
        font-size: 2rem;
      }
    }
  }

  // &__none {
  //   // background-color: var(--clr-theme1);
  //   min-height: 20vh;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-family: var(--font-title);
  //   font-size: var(--font-xl);
  //   color: var(--clr-theme1);
  //   // display: none;
  // }

  &__content {
    padding: 2rem;
    @media (max-width: u.$bp-md) {
      padding: 0.1rem;
    }
    &__article {
      font-size: var(--font-md);
      box-shadow: var(--box-shadow-black);
      margin-bottom: 2rem;
      padding: 1rem;
      background-color: var(--clr-light);
      color: var(--clr-dark);
      // border-radius: 1rem;
      border: 0.4rem solid var(--clr-dark);
      transform: var(--skew-left);

      &__title {
        font-weight: var(--font-w-semibold);
      }
      &__tags {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;

        .tag {
          font-size: var(--font-xs);
          transform: var(--skew-right-more);
          // background-color: var(--clr-dark);
          // color: var(--clr-light);

          // &:hover {
          //   background-color: var(--clr-accent1);
          //   color: var(--clr-dark);
          // }
        }
      }
      @media (max-width: u.$bp-md) {
          font-size: var(--font-xs);
          padding: 0.5rem;
      }
    }
  }

  &__pages {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: var(--font-w-bold);
    flex-wrap: wrap;
    margin-bottom: 3rem;

    &__link {
      background-color: var(--clr-dark);
      color: var(--clr-light);
      padding: 0.2rem 0.5rem;
      margin: 0.2rem;
      border-radius: 0.5rem;
      border: 0.2rem solid var(--clr-light);
      transition: var(--transition);

      &:hover {
        background-color: var(--clr-accent1);
        color: var(--clr-dark);
        cursor: pointer;
      }

      &.cur {
        background-color: var(--clr-accent1);
        color: var(--clr-dark);
      }
    }
  }
}



@use '../scss-utils' as u;

.footer {
  background-color: var(--clr-dark);
  color: var(--clr-light);
  height: 17rem;
  font-family: var(--font-title);
  padding-left: 3.5rem;
  padding-right: 2rem;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;

  &__content {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    // border: yellow solid 1px;

    &__title {
      // border: red solid 1px;
      margin-right: 7rem;
      h1, a {
      line-height: 1;
      font-size: 4rem;
      font-weight: var(--font-w-bold);
      padding-right: 0;
      span {
        color: var(--clr-theme2);
      }
    }

    @media (min-width: u.$bp-md) {
      margin-right: 12rem;
    }
  }

    &__links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: flex-end;
      font-size: 1.5rem;
      padding-left: 0;
      // border: green solid 1px;

      a:not(:last-child) {
        margin-right: 2rem;
      }
      a:hover {
        color: var(--clr-theme2);
      }

      @media (min-width: u.$bp-md) {
        flex-direction: row;
        font-size: 2rem;
      }
    }
  }
}
@use '../scss-utils' as u;
.featured {
  background-color: var(--clr-accent1);
}

.latest {
  background-color: var(--clr-accent2);
  // .article__title {
  //   border-top-left-radius: 0.5rem;
  //   border-top-right-radius: 5rem;
  // }
}

.articles {

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;

    @media (min-width: u.$bp-lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: u.$bp-xlg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.article {
  box-shadow: var(--box-shadow-black);
  border: solid 0.3rem var(--clr-black);
  transform: var(--skew-left);
  // max-width: 35rem;
  // height: 10rem;
  text-align: center;
  background-color: var(--clr-light);
  // border-radius: 1rem;
  margin: var(--gap-sm);
  color: var(--clr-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  // outline: solid 0.5rem var(--clr-dark);
  // outline-offset: -0.5rem;

  @media (min-width: u.$bp-md) {
    height: 15rem;
  }
  
  &__title {
    height: 60%;
    // border-top-left-radius: 0.5rem;
    // border-top-right-radius: 0.5rem;
    padding: var(--gap-md);
    // background-color: var(--clr-light);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-weight: var(--font-w-bold);
    font-size: var(--font-sm);
  }

  &__tags {
    // height: 35%;
    // background-color: yellow;
    margin: var(--gap-sm);
    // padding: u.$gap-sm;
    // border: solid 2px magenta;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;

    .tag {
      font-size: var(--font-xs);
      transform: var(--skew-right-more);
      // background-color: var(--clr-accent2);
      // // border-color: var(--clr-accent2);
      // color: var(--clr-light);
      // &:hover {
      //   color: var(--clr-black);
      // }
    }
  }
}


@use '../scss-utils' as u;

.toc-out {
  // background-color: var(--clr-theme2);
  // border: 1px solid red;
  // padding: 3rem;
  padding-top: 10rem;
  display: none;
  @media (min-width: u.$bp-lg) {
    display: block;
  }
}
.toc {
  // background-color: yellow;
  position: sticky;
  top: 7rem;
  font-family: var(--font-title);
  color: var(--clr-theme1);
  // border: 0.5rem solid var(--clr-theme1);
  // border-radius: 1rem;
  &__title {
    font-size: var(--font-xl);
    text-align: center;
    margin-bottom: 2rem;
    font-weight: var(--font-w-medium);   
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    padding: var(--gap-xs);

    li {
      font-size: var(--font-md);
      font-weight: var(--font-w-medium);
      margin: var(--gap-md) 0;
      & > a:hover {
        color: var(--clr-theme2);
      }
      &.active > a {
        color: var(--clr-accent1);
      }
    }
  }
}


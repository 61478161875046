@use '../scss-utils' as u;

.hero {
  position: relative;
  display: flex;
  background: var(--clr-light);
  height: 18rem;
  justify-content: center;
  align-items: flex-end;
  line-height: 1;

  h1 {
    font-family: var(--font-title);
    font-size: 9rem;

    span {
      color: var(--clr-theme1);
    }

    @media (min-width: u.$bp-md) {
      font-size: 10rem;
    }
  }
}
@forward 'css-variables';
@use 'scss-utils' as u;

// #########################
// boilerplate
// #########################
html, body {
  font-size: 100%;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
}

a,
a:visited,
a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
  transition: var(--transition);
}

a:focus, a:hover {
  cursor: pointer;
  color: var(--clr-theme1);
}

ul {
  list-style: none;
  padding-left: 1rem;
  line-height: 1.2;
}

body {
  font-family: var(--font-regular);
  font-weight: var(--font-w-medium);
  font-size: var(--font-xs);
  line-height: 1.6;
  color: var(--clr-black);
  background-color: var(--clr-dark);

  @media (min-width: u.$bp-sm) {
    font-size: var(--font-sm);
  }
  @media (min-width: u.$bp-md) {
    font-size: var(--font-md);
  }
}

::selection {
  background: var(--clr-accent3);
}
// #########################
// utility classes
// #########################

// padding
.padding {
  &--all {
    padding: var(--gap-sm);

    @media (min-width: u.$bp-md) {
      padding: var(--gap-md);
    }
  }
  &--x {
    padding-left: var(--gap-sm);
    padding-right: var(--gap-sm);

    @media (min-width: u.$bp-md) {
      padding-left: var(--gap-md);
      padding-right: var(--gap-md);
    }
  }
}

// max width and common margin
.max-width {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

// common section title settings for each section
.section-title {
  display: block;
  margin: var(--gap-sm);
  font-size: var(--font-xl);
  font-family: var(--font-title);
  font-weight: var(--font-w-medium);

  @media (min-width: u.$bp-md) {
    margin: var(--gap-lg);
    font-size: var(--font-xxl);
  }

  &__more {
    font-size: var(--font-sm);
    background-color: var(--clr-theme2);
    padding: 0 0.3rem;
    border-radius: 0.5rem;

    &:hover {
      background: var(--clr-theme1);
      color: var(--clr-dark);
    }

    @media (min-width: u.$bp-md) {
      font-size: var(--font-md);
    }
  }
}

// common tag settings for each section






@use '../scss-utils' as u;

.blogcontent {
  // min-height: 60vh;
  // padding: 3rem;
  // padding-top: 7rem;
  // display: flex;
  // flex-direction: column;
  // border: 1px red solid;

  &__title {
    font-size: var(--font-md);
    text-align: center;
    margin: var(--gap-md) 0 var(--gap-sm) 0;
    // border: 1px solid green;

    @media (min-width: u.$bp-sm) {
      font-size: var(--font-lg);
    }
    @media (min-width: u.$bp-md) {
      font-size: var(--font-xl);
      margin: calc(2*var(--gap-md)) 0 calc(2*var(--gap-sm)) 0;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3rem 0;
    // border: 1px red solid;
     .tag {
      background-color: var(--clr-accent2);
      color: var(--clr-light);
      font-size: var(--font-xs);
      border-color: var(--clr-accent2);

      &:hover {
        background-color: var(--clr-accent1);
        color: var(--clr-black);
      }

      @media (min-width: u.$bp-md) {
        font-size: var(--font-sm);
      }
     }
    }

  &__content {
    font-size: var(--font-xs);
    @media (min-width: u.$bp-sm) {
      font-size: var(--font-sm);
    }
    // border: 1px solid orange;
    h2, h3 {
      margin: var(--gap-lg) 0 var(--gap-md) 0;
      scroll-margin-top: var(--scroll-margin-top);
      @media (min-width: u.$bp-md) {
        margin: var(--gap-xl) 0 var(--gap-lg) 0;
      }
    }

    h2 {
      font-size: var(--font-sm);
      @media (min-width: u.$bp-sm) {
        font-size: var(--font-md);
      }
      @media (min-width: u.$bp-md) {
        font-size: var(--font-lg);
      }
    }

    h3 {
      font-size: var(--font-xs);
      @media (min-width: u.$bp-sm) {
        font-size: var(--font-sm);
      }
      @media (min-width: u.$bp-md) {
        font-size: var(--font-md);
      }
    }
    
    img, p {
      margin: 1rem 0;
    }

    img {
      width: 100%;
      max-width: 800px;
      &.sm {
        max-width: 400px;
      }
      &.lg {
        max-width: 100%;
      }
    }

    a {
      text-decoration: underline;
    }
  }
}





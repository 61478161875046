@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Oswald:wght@400;500;700&display=swap');

:root {

  // color
  --clr-light: #d8d0cd;
  --clr-black: #100f0f;
  --clr-accent3: #af3a54;
  --clr-accent4: #bfb7b6;
  
  // --clr-accent3: #e7c381;
  // --clr-accent3: #9a3c76;
  // --clr-accent3: #498957;
  // --clr-accent3: #8c6d80;

  // --clr-theme1: #774557;
  // --clr-theme2: #3b556d;
  // --clr-accent1: #b58a53;
  // --clr-accent2: #91695a;
  // --clr-dark: #3d312b;

  // --clr-theme1: #a660a7;
  // --clr-theme2: #348ec7;
  // --clr-accent1: #d4af37;
  // --clr-accent2: #c3552b;
  // --clr-dark: #100f0f;

  --clr-theme1: #9182ad;
  --clr-theme2: #86b3c3;
  --clr-accent1: #d4af37;
  --clr-accent2: #54457e;
  --clr-dark: #100f0f;


  --clr-gradient: linear-gradient(120deg, 
  #A660A7 20%, var(--clr-dark) 20% 21%, 
  #348EC7 21% 40%, var(--clr-dark) 40% 41%, 
  #960018 41% 60%, var(--clr-dark) 60% 61%, 
  #D4AF37 61% 80%, var(--clr-dark) 80% 81%, 
  #C3552B 80%);

  // --clr-gradient2: linear-gradient(175deg, var(--clr-light) 20%, 
  // var(--clr-theme1) 20% 80%, 
  // var(--clr-accent2) 80%);

  --clr-gradient2: linear-gradient(165deg, 
  var(--clr-light) 52%,
  var(--clr-dark) 52%);

  // box shadow
  // --box-shadow-black: 3px 1px 0px 2px, 0px -1px 0px 2px rgba(35, 31, 32, 0.62);

  --box-shadow-black: 5px 5px 0px 3px, 0px -1px 0px 2px var(--clr-dark);

  // font family
  --font-regular: 'Open Sans', sans-serif;
  --font-title: 'Oswald', sans-serif;

  // font weight
  --font-w-normal: 400;
  --font-w-medium: 500;
  --font-w-semibold: 600;
  --font-w-bold: 700;

  // font size
  --font-xxs: 0.5rem;
  --font-xs: 0.7rem;
  --font-sm: 1rem;
  --font-md: 1.2rem;
  --font-lg: 1.5rem;
  --font-xl: 2rem;
  --font-xxl: 2.5rem;

  // nav bar font size
  --nav-font-size: 2rem;

  // gaps
  --gap-xs: 0.2rem;
  --gap-sm: 0.5rem;
  --gap-md: 1rem;
  --gap-lg: 1.5rem;
  --gap-xl: 2rem;

  // transition
  --transition: 500ms ease-in-out;

  // navbar height
  --nav-height-sm: 4rem;
  --nav-height-md: 7rem;

  // scroll margin top
  --scroll-margin-top: 144px;

  // skew
  --skew-left: skew(7deg, 0);
  --skew-right: skew(-7deg, 0);
  --skew-right-more: skew(-14deg, 0);
}

@use '../scss-utils' as u;
// ###########################################
// general header settings
// header include both navbar and side menu
// ###########################################
header {
  position: sticky;
  top: 0;
  z-index: 1;
}
// when sidemenu is open
.sideopen {
  // hamburger button transform to x
  .nav__toggle {
    >span:first-child {
      transform: rotate(45deg);
    }

    >span:nth-child(2) {
      opacity: 0;
    }

    >span:last-child {
      transform: rotate(-45deg);    
    }
  }
  // sidemenu move in
  .sidemenu {
    left: 0;
  }
}

// ##############################
// the horizontal navbar
// ##############################
.navout {
  position: relative;
  width: 100%;
  // top: 0;
  z-index: 10;
  background-color: var(--clr-light);

  padding: 0;
  margin: 0;
}

// .navout-border {
//   @extend .navout;
//   // border-bottom: 1px solid rgba(0, 0, 0, 0.3);
//   border-bottom: 10px solid red;
// }

.nav {
  // border: solid 1px red;
  // position: sticky;
  // top: 0;
  // left: 0;
  // right: 0;
  height: var(--nav-height-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-top: 1rem;
  font-size: var(--nav-font-size);

  @media (min-width: u.$bp-md) {
    height: var(--nav-height-md);
  }

  // logo
  &__logo {
    // flex-grow: 0;
    // flex-shrink: 0;
    // border-radius: 50%;
    // border: 0.12rem solid var(--clr-dark);
    // width: 3rem;
    // height: 3rem;
    // background-color: var(--clr-theme);
    display: flex;
    justify-content: center;
    align-self: end;

    // border: 1px solid green;
    line-height: 1;
    // margin: 0 1rem;
    flex: 1 1 0;
    
    &__title {
      font-family: var(--font-title);
      font-size: 4rem;
      font-weight: var(--font-w-bold);

      &:hover {
        color: var(--clr-theme2);
      }

      span {
        color: var(--clr-theme1);
      }
      @media (min-width: u.$bp-md) {
        font-size: 6rem;
      }
    }

    // the theme button, only for blog pages
    &__theme {
      margin-bottom: 0.5rem;
      margin-left: 0.2rem;
      width: 2.5rem;
      align-self: flex-end;
      padding: 0.2rem 0.4rem;
      font-family: var(--font-title);
      font-weight: var(--font-w-normal);
      font-size: var(--font-xs);
      border-radius: 0.5rem;
      background-color: var(--clr-accent1);
      display: none;
      transition: var(--transition);

      &:hover {
        cursor: pointer;
        background-color: var(--clr-theme2);
      }

      @media (min-width: u.$bp-md) {
        font-size: var(--font-sm);
        margin-left: 0.5rem;
        width: 3rem;
        line-height: 1.2;
      }
    }
  }

  // search bar, including the input field and button
  &__search {
    // width: min(80%, 25rem);
    height: 2.2rem;
    display: flex;
    justify-content: space-between;
    flex: 1 1 0;

    box-shadow: var(--box-shadow-black);
    border: 2px solid var(--clr-dark);
    // transform: skew(-3deg, 0deg);
    button {
      margin: 0.15rem;
      transition: var(--transition);
    }
    input, button {
      border: none;
      outline: none;
      background-color: var(--clr-light);
      font-size: 1rem;
      font-weight: var(--font-w-bold);
    }
    input {
      width: 100%;
    }

    button:hover {
      cursor: pointer;
      color: var(--clr-light);
      background-color: var(--clr-theme1);
    }
  }

  // all the horizontal fast links
  &__links {
    // border: 1px solid orange;
    display: none;
    font-family: var(--font-title);
    font-weight: var(--font-w-medium);

    @media (min-width: u.$bp-md) {
      display: flex;
      justify-content: space-evenly;
      flex: 1 1 0;
    }
  }

  // hamburger button
  &__toggle {
    @media (min-width: u.$bp-md) {
      display: none;
    }

    &:hover {
      cursor: pointer;

      & span:nth-child(2) {
        transform: translateX(1rem);
      }
    }

    span {
      display: block;
      width: 2rem;
      height: 0.3rem;
      background-color: black;

      transition: var(--transition);
      transform-origin: 0.2rem 0.2rem;

      // make middle line shorter for aesthetic
      &:nth-child(2) {
        width: 1rem;
      }

      &:not(:last-child) {
        margin-bottom: 0.3rem;
      }
    }
  }
}

// ##############################
// the vertical sidebar
// ##############################
.sidemenu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 9;
  background-color: var(--clr-accent1);
  width: 100%;

  transition: var(--transition);

  a {
    font-family: var(--font-title);
    font-size: var(--font-xl);
  }
  a:not(:last-child) {
    margin-bottom: var(--gap-md);
  }
}
